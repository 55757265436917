import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AssessRequestRaService } from 'src/app/assess-request-ra/assess-request-ra.service';
import { requestFlags } from 'src/app/common/models/PureRuleDetailsModel';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { DataService } from 'src/app/common/services/data.service';
import { environment } from 'src/environments/environment';
import { WORKLIST_EXCEL_ADDITIONAL_COLUMNS, WORKLIST_EXCEL_ADDITIONAL_COLUMNS_REPORT } from 'src/app/common/models/tableColumns';
import { WorklistService } from '../../worklist.service';

@Component({
  selector: 'app-my-worklist',
  templateUrl: './my-worklist.component.html',
  styleUrls: ['./my-worklist.component.scss']
})
export class MyWorklistComponent implements OnInit {
  @ViewChild('rowHoverDiv') rowHoverDiv: ElementRef;
  projectdetailData: any = [];
  isClickHereComments: boolean;
  myworkListData: any;
  selectedRows: any = [];
  filteredRows: any = [];
  exportDialog = false;
  emptyworklistmessage = '';
  selectedRowData: any;
  loading = true;
  myWorklistTableColumns = [];
  myWorklistTableColumnsClear = [];
  flotingDivFlag = false;
  loadingMyWorklistMessage;
  rowData;
  delegateDialog = false;
  deleteDialog = false;
  selfAssignPopUp = false;
  deligatOrForwardEmailId: string;
  deligateComment: string;
  forwardToRaComment: string;
  forwardToRaPopUp = false;
  listOfEmails = [];
  usersList;
  userEmailIdSuggessionList: string[];
  userEmailList = [];
  filteredUserListVals = [];
  columnsDialog = false;
  userVal;
  delegateOrForwardBtnFlag = true;
  delegateORForward: string;
  worklistSelectedData;
  rndDelete = false;
  rejectRequest = false;
  requestStatus;
  emailRequestStatus;
  rejectionComments = '';
  position: string;
  plmvalidityarea = {
    validityAreaCode: '',
    validityDescription: '',
    validity: '',
  };
  ReportCommentsHyperLinks = [];
  formAssess = {
    market: [],
    division: '', formulationCategory: [], formulationType: [], formulationClass: [], formulationSubClass: [],
    fc: [], version: [], alternate: [], recipeType: [],
    description: [], name: [], quantity: [], uom: [], intendedUse: 'Any',
    prepMethod: [], comments: '', dosage: '', dosageUom: '', requestStatus: '', servingSize: '',
    selectedMarkets: [], tfcList: [], firstRequest: '', cucChanged: '', keyDate: '', requestDisplayID: '', isMultiComponent: '', multiComponent: [], dosageForMarkets: [],
    divisionid: '', raUserId: '', createdByUserId: '', ifraComplianceModelList: [], regionalComplianceLevel2ModelList: [], regionalComplianceLevel3ModelList: [], projectdetailData: [],
    plmvalidityarea: this.plmvalidityarea, plmkeydate: ''
  };
  myWorklistColumnsOptions: any;
  userForm: FormGroup;
  columnsAdded: any[] = [];
  columnToBeAddedDetails: any[] = [];
  columnListToAdd: any;
  allMyWorklistColumns: any;
  orgMyWorklistColumns: any;
  myworklistColumnsCustomisedToShow: any[] = [];
  settings: any;
  selectedColumns: any[];
  myWLuserDetails;
  isRnD = false;
  isRA = false;
  isRnDReq = false;
  isSuperAdmin = false;
  myWLActionCount = 0;
  CommentsHyperLinks = [];
  myWorklistTableExcelColumns = [];
  isClickHere = false;
  tableComment = '';
  commentRD = '';
  isColumnsHasHyperLinks = 'No';
  HyperLinksStartColumnNumber = 0;
  frozenColsInWorklist = [];

  constructor(
    private router: Router,
    public worklistservice: WorklistService,
    public matSnackBar: MatSnackBar,
    private route: Router,
    private dataTrans: DataService,
    public assessraService: AssessRequestRaService,
    private fb: FormBuilder
  ) {
    this.userForm = this.fb.group({
      name: [],
      column: this.fb.array([
        this.fb.control(null)
      ])
    });
  }

  ngOnInit() {
    this.getMyWLUserRoleDetails();
    localStorage.setItem('isViewReportClicked', 'false');
    localStorage.removeItem('requestStatus');
    this.getColumnCustomization();
    this.settings = {
      singleSelection: false,
      idField: 'fieldName',
      textField: 'fieldDescription',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'Un Select All',
      allowSearchFilter: true,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No data',
      closeDropDownOnSelection: true,
      itemsShowLimit: 2
    };
  }
  sendRequestId(user) {
    this.dataTrans.updateRequestId(user);
  }
  /* istanbul ignore next */
  getColumnCustomization() {
    this.loading = true;
    const apiurl = environment.APIFAsT + '/getcolumncustomization/newmyworklist';
    this.worklistservice.getWorklistApiData(apiurl).subscribe((response) => {
      this.myworklistColumnsCustomisedToShow = response;
      this.getWorklistColumns();
    });
  }
  /* istanbul ignore next */
  getWorklistColumns() {
    const apiurl = environment.APIFAsT + '/getworklistcolumns/newmyworklist';
    this.worklistservice.getWorklistApiData(apiurl).subscribe((response) => {
      this.allMyWorklistColumns = response;
      this.getMyWorkList();
    });
    const apiurlworklist = environment.APIFAsT + '/getworklistcolumns/myworklist';
    this.worklistservice.getWorklistApiData(apiurlworklist).subscribe((response) => {
      this.orgMyWorklistColumns = response;
    });
  }
  /* istanbul ignore next */
  initiateColumnOptions() {
    this.columnsAdded = [];
    this.columnListToAdd = this.orgMyWorklistColumns;
    if (this.myworklistColumnsCustomisedToShow.length) {
      this.columnsAdded = this.myworklistColumnsCustomisedToShow;
      WORKLIST_EXCEL_ADDITIONAL_COLUMNS.forEach(col => {
        this.columnsAdded = this.columnsAdded.filter(x => x.fieldName !== col.fieldName);
      });
      WORKLIST_EXCEL_ADDITIONAL_COLUMNS_REPORT.forEach(col => {
        this.columnsAdded = this.columnsAdded.filter(x => x.fieldName !== col.fieldName);
      });
    } else {
      this.allMyWorklistColumns.forEach(column => {
        if (column.fieldName === 'check' ||
          column.fieldName === 'requestDisplayID' || column.fieldName === 'requestStatus') {
          this.columnsAdded.push(column);
        }
      });
    }
    this.columnsAdded.forEach(col => {
      this.columnListToAdd = this.columnListToAdd.filter(x => x.fieldName !== col.fieldName);
    });
    WORKLIST_EXCEL_ADDITIONAL_COLUMNS.forEach(col => {
      this.columnListToAdd = this.columnListToAdd.filter(x => x.fieldName !== col.fieldName);
    });
    WORKLIST_EXCEL_ADDITIONAL_COLUMNS_REPORT.forEach(col => {
      this.columnListToAdd = this.columnListToAdd.filter(x => x.fieldName !== col.fieldName);
    });
  }

  getMyWorkList() {
    this.myWorklistTableColumns = [];
    this.myWorklistColumnsOptions = [];
    if (this.myworklistColumnsCustomisedToShow.length) {
      this.myWorklistTableColumns = this.myworklistColumnsCustomisedToShow;
      this.myWorklistTableExcelColumns = this.myworklistColumnsCustomisedToShow;
    } else {
      this.myWorklistTableColumns = this.allMyWorklistColumns;
      this.myWorklistTableExcelColumns = this.orgMyWorklistColumns;
    }
    /* Frozen-Column Code */
    this.frozenColsInWorklist = this.myWorklistTableColumns.filter(obj =>
      obj.fieldName === 'requestDisplayID' || obj.fieldName === 'requestStatus');
    this.myWorklistTableColumns = this.myWorklistTableColumns.filter(obj =>
      obj.fieldName !== 'requestDisplayID' && obj.fieldName !== 'requestStatus');

    if (this.myWorklistTableColumns && this.myWorklistTableColumns.length > 0) {
      this.myWorklistTableColumns.sort((a3, b3) => (a3.sortOrder > b3.sortOrder) ? 1 : -1);
      const sortedData = this.myWorklistTableColumns;
      this.myWorklistTableColumns = sortedData;
      this.myWorklistTableColumns = JSON.parse(JSON.stringify(this.myWorklistTableColumns));
    }
    this.loading = true;
    this.loadingMyWorklistMessage = 'Getting Worklist Details';
    const apiurl = environment.APIFAsT + '/execution/requests/noDate/myworklist';
    this.worklistservice.getWorklistApiData(apiurl).subscribe((response1) => {
      this.loading = false;
      this.emptyworklistmessage = '';
      if (response1.length !== 0) {
        this.myworkListData = response1.map(respElem => {
          this.changeComment(respElem);
          this.splitLinks(respElem.comments, 'comment');
          this.splitLinksReport(respElem.reportComments, 'Reportcomment');
          const marketId = respElem.market.marketId;
          const reqCategoryID = (respElem.tfc.tfcLevel === 5) ? respElem.tfc.parentTFC.parentTFC.parentTFC.tfcId : respElem.tfc.parentTFC.parentTFC.tfcId;
          const divisionId = (respElem.tfc.tfcLevel === 5) ? respElem.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcId : respElem.tfc.parentTFC.parentTFC.parentTFC.tfcId;
          return {
            requestDisplayID: respElem.requestDisplayID,
            cucCode: respElem.cucCode,
            cucDescription: respElem.cucDescription,
            keyDate: respElem.keyDate,
            validityArea: respElem.validityArea,
            requestNotification: respElem.requestNotification,
            recipeType: respElem.recipeType,
            recipeStatus: respElem.recipeStatus,
            marketId: respElem.market.marketId,
            marketName: respElem.market.marketName,
            projectName: respElem.market.projDetail === null || respElem.market.projDetail === undefined ? '' : respElem.market.projDetail.projectName,
            projectDescription: respElem.market.projDetail === null || respElem.market.projDetail === undefined ? '' : respElem.market.projDetail.projectDescription,
            projectTypeDesc: respElem.market.projDetail === null || respElem.market.projDetail === undefined ? '' : respElem.market.projDetail.projectTypeDesc,
            brandDesc: respElem.market.projDetail === null || respElem.market.projDetail === undefined ? '' : respElem.market.projDetail.brandDesc,
            launchTimeLine: respElem.market.projDetail === null || respElem.market.projDetail === undefined ? '' : respElem.market.projDetail.launchTimeLine,
            manufactureCountryDescription: respElem.market.projDetail === null || respElem.market.projDetail === undefined ? '' : respElem.market.projDetail.manufactureCountryDescription,
            manufactureSiteDesc: respElem.market.projDetail === null || respElem.market.projDetail === undefined ? '' : respElem.market.projDetail.manufactureSiteDesc,
            division: (respElem.tfc.tfcLevel === 5) ? respElem.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcName : respElem.tfc.parentTFC.parentTFC.parentTFC.tfcName,
            rDFormulationCategory: (respElem.tfc.tfcLevel === 5) ? respElem.tfc.parentTFC.parentTFC.parentTFC.tfcName : respElem.tfc.parentTFC.parentTFC.tfcName,
            formulationType: (respElem.tfc.tfcLevel === 5) ? respElem.tfc.parentTFC.parentTFC.tfcName : respElem.tfc.parentTFC.tfcName,
            formulationClass: (respElem.tfc.tfcLevel === 5) ? respElem.tfc.parentTFC.tfcName : respElem.tfc.tfcName,
            formulationSubClass: (respElem.tfc.tfcLevel === 5) ? respElem.tfc.tfcName : '',
            preparationMethod: respElem.preparationMethod,
            intendedUse: respElem.intendedUse,
            multicomponent: respElem.isMultiComponent,
            requestStatus: respElem.requestStatus,
            ra: respElem.ra,
            allRa: respElem.allRA,
            submittedDate: respElem.submittedDate,
            submittedByUserId: respElem.submittedByUserId,
            submittedByUserName: respElem.submittedByUserName,
            comments: respElem.comments,
            assignedDate: respElem.assignedDate,
            inProgressRADate: respElem.inProgressRADate,
            inProgressRDDate: respElem.inProgressRNDDate,
            inProgressSIRDate: respElem.inProgressSIRDate,
            rejectedDate: respElem.rejectedDate,
            deletionDate: respElem.deletionDate,
            completedDate: respElem.completedDate,
            rdComment: this.commentRD,
            raComment: respElem.raComment,
            // reportComments: this.changeReportComment(respElem.reportComments),
            reportComments: respElem.reportComments === null || respElem.reportComments === undefined || respElem.reportComments === '' ? '' : 'RA Comment',
            // rpcomments: respElem.reportComments,
            rpcomments: respElem.reportComments === null || respElem.reportComments === undefined || respElem.reportComments === '' ? '' : 'RA Comment',
            topTfcId: respElem.tfcid,
            allRAIDs: respElem.allRAIDs,
            raUserID: respElem.raUserIDred,
            sentBackDate: respElem.sentBackDate,
            reSubmittedDate: respElem.reSubmittedDate,
            divisionid: (respElem.tfc.tfcLevel === 5) ? respElem.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcId : respElem.tfc.parentTFC.parentTFC.parentTFC.tfcId,
            compositionStatus: respElem.compositionStatus,
            productStatus: respElem.productStatus,
            rdcommentLink1: this.CommentsHyperLinks[0],
            rdcommentLink2: this.CommentsHyperLinks[1],
            rdcommentLink3: this.CommentsHyperLinks[2],
            rdcommentLink4: this.CommentsHyperLinks[3],
            rdcommentLink5: this.CommentsHyperLinks[4],
            rdcommentLink6: this.CommentsHyperLinks[5],
            rdcommentLink7: this.CommentsHyperLinks[6],
            reportCommentsLink1: this.ReportCommentsHyperLinks[0],
            reportCommentsLink2: this.ReportCommentsHyperLinks[1],
            reportCommentsLink3: this.ReportCommentsHyperLinks[2],
            reportCommentsLink4: this.ReportCommentsHyperLinks[3],
            reportCommentsLink5: this.ReportCommentsHyperLinks[4],
            reportCommentsLink6: this.ReportCommentsHyperLinks[5],
            reportCommentsLink7: this.ReportCommentsHyperLinks[6],
            rdPlainComment: this.handleSpecialCharacters(respElem.comments),
            PlainReportComment: this.handleSpecialCharacters(respElem.reportComments),
            tfc1status: respElem.tfc1status,
            selfAssignRequest: this.getActionStatusBasedonRole(marketId, divisionId, 'selfAssignRequest', reqCategoryID),
            delegateRequest: this.getActionStatusBasedonRole(marketId, divisionId, 'delegateRequest', reqCategoryID),
            forwardtoRaRequest: this.getActionStatusBasedonRole(marketId, divisionId, 'forwardtoRaRequest', reqCategoryID),
            rejectRequest: this.getActionStatusBasedonRole(marketId, divisionId, 'rejectRequest', reqCategoryID),
            editRequest: this.getActionStatusBasedonRole(marketId, divisionId, 'editRequest', reqCategoryID),
            deleteRequest: this.getActionStatusBasedonRole(marketId, divisionId, 'deleteRequest', reqCategoryID)
          };
        });
        this.selectedRows = this.myworkListData;
      } else {
        this.myworkListData = [];
      }
    }, (error) => {
      this.loading = false;
      this.emptyworklistmessage = 'No Records Found';
    });

  }

  onFilter(event) {
    if (event.filters != null) {
      Object.keys(event.filters).length === 0
        ? (this.filteredRows = [])
        : (this.filteredRows = event.filteredValue);
    }
  }

  showExportDialog() {
    if (this.myworklistColumnsCustomisedToShow.length) {
      this.myWorklistTableExcelColumns = this.myworklistColumnsCustomisedToShow;
    } else {
      this.myWorklistTableExcelColumns = this.orgMyWorklistColumns;
    }
    const rdHashyerlinks = this.myWorklistTableExcelColumns.filter(x => x.fieldName === 'rdComment').length > 0 ? 'Yes' : 'No';
    const reportHashyerlinks = this.myWorklistTableExcelColumns.filter(x => x.fieldName === 'reportComments').length > 0 ? 'Yes' : 'No';
    if (rdHashyerlinks === 'Yes' || reportHashyerlinks === 'Yes') {
      this.isColumnsHasHyperLinks = 'Yes';
    } else {
      this.isColumnsHasHyperLinks = 'No';
    }
    if (this.isColumnsHasHyperLinks === 'Yes') {
      let totalHyperlinksCount = 0;
      if (this.myWorklistTableExcelColumns.filter(x => x.fieldName === 'rdcommentLink1').length === 0 && rdHashyerlinks === 'Yes') {
        this.addExtraColumns();
      }
      if (this.myWorklistTableExcelColumns.filter(x => x.fieldName === 'reportCommentsLink1').length === 0 && reportHashyerlinks === 'Yes') {
        this.addExtraColumnsReport();
      }
      if (rdHashyerlinks === 'Yes') {
        totalHyperlinksCount = this.myWorklistTableExcelColumns.findIndex(x => x.fieldName === 'rdcommentLink1');
      } else if (reportHashyerlinks === 'Yes' && totalHyperlinksCount === 0) {
        totalHyperlinksCount = this.myWorklistTableExcelColumns.findIndex(x => x.fieldName === 'reportCommentsLink1');
      }
      this.HyperLinksStartColumnNumber = totalHyperlinksCount + 1;
    }
    this.exportDialog = true;
  }

  redirectTo(request: any) {
    this.sendRequestId(request);
    let formulationData = [];
    const pureCodeList = [];
    if (request.requestStatus === 'In Progress – R&D') {
      recipeModel.requestStatus = request.requestStatus;
      const apiurl = environment.APIFAsT + '/executionrequest/' + request.requestDisplayID;
      this.assessraService.getData(apiurl).subscribe((response) => {
        if (response !== null) {
          Object.assign(recipeModel, response);
          recipeModel.marketDetails = response.marketDetails;
          recipeModel.tfcFullText = response.tfc.tfcFullText;
          if (response.tfc.tfcLevel === 5) {
            recipeModel.formulationSubClass = response.tfc.tfcName;
            const tfc5parent = response.tfc.parentTFC;
            if (tfc5parent.tfcLevel === 4) {
              recipeModel.formulationClass = tfc5parent.tfcName;
              const tfc4parent = tfc5parent.parentTFC;
              if (tfc4parent.tfcLevel === 3) {
                recipeModel.formulationType = tfc4parent.tfcName;
                const tfc3parent = tfc4parent.parentTFC;
                if (tfc3parent.tfcLevel === 2) {
                  recipeModel.formulationCategory = tfc3parent.tfcName;
                  const tfc2parent = tfc3parent.parentTFC;
                  if (tfc2parent.tfcLevel === 1) {
                    recipeModel.division = tfc2parent.tfcName;
                  }
                }
              }
            }
          }
          formulationData = response.formulationRequestDetails;
          if (formulationData) {
            for (let i = 0; i < formulationData.length; i++) {
              pureCodeList[i] = formulationData[i].pureCode;
            }
            recipeModel.pureCodeList = pureCodeList;
          }
          this.dataTrans.setComponentName('AssessRequestsRA');
          this.dataTrans.setSelectedStep(2);
          this.worklistservice.setProfileObs(request.requestDisplayID);
          this.router.navigate(['assess-request-ra/execute-rules']);
        }
      });
    } else {
      recipeModel.allRAIDs = request.allRAIDs;
      recipeModel.raUserID = request.raUserID;
      recipeModel.ra = request.ra;
      recipeModel.requestStatus = request.requestStatus;
      const url = '/assess-request-ra/verify-request/' + request.requestDisplayID;
      this.router.navigate([url]);
      this.dataTrans.setComponentName('AssessRequestsRA');
      this.dataTrans.setSelectedStep(0);
      this.worklistservice.setProfileObs(request.requestDisplayID);
      localStorage.setItem('submittedByUserId', request.submittedByUserId);
    }
  }
  /* istanbul ignore next */
  showFloatingIcons(event, rowData) {
    this.selectedRowData = rowData;
    event.target.dataTypeElem = 'row';
    this.flotingDivFlag = true;
    this.rowData = rowData;
    setTimeout(() => {
      const d = document.getElementById('rowHoverDiv');
      const adj = (window.screen.width === window.outerWidth) ? 0 : -15;
      const maxScreenWidth = window.innerWidth * (window.screen.width / (window.outerWidth + adj));
      d.style.left = String(event.pageX + 15) + 'px';
      d.style.display = 'block';
      this.myWLActionCount = d.getElementsByTagName('input').length;
      if (this.myWLActionCount === 0) {
        d.style.display = 'none';
      }
      if (event.pageY > 400) {
        if (this.myWLActionCount === 1) {
          d.style.top = (event.pageY - 15) + 'px';
        } else if (this.myWLActionCount === 2) {
          d.style.top = (event.pageY - 40) + 'px';
        } else if (this.myWLActionCount === 3) {
          d.style.top = (event.pageY - 65) + 'px';
        } else if (this.myWLActionCount === 4) {
          d.style.top = (event.pageY - 100) + 'px';
        } else if (this.myWLActionCount === 5) {
          d.style.top = (event.pageY - 125) + 'px';
        } else if (this.myWLActionCount === 6) {
          d.style.top = (event.pageY - 150) + 'px';
        }
      } else {
        d.style.top = (event.pageY - 10) + 'px';
      }
    }, 100);


  }

  /* istanbul ignore next */
  hideFloatingIcons() {
    this.rowHoverDiv.nativeElement.style.display = 'none';
  }

  mousehoverpop(event) {
    event.stopPropagation();
  }
  MyWlAssignRequest(event) {
    if (this.selectedRowData.requestStatus === 'Submitted' || this.selectedRowData.requestStatus === 'Re-Submitted') {
      this.MyWlAssignToSelf(this.selectedRowData.requestDisplayID, this.selectedRowData.submittedByUserId);
    } else {
      this.matSnackBar.open(this.selectedRowData.requestStatus + ' Request cannot be Self Assign', 'close', {
        duration: 3900,
        panelClass: ['error']
      });
    }

    event.stopPropagation();
    return false;



  }
  MyWlAssignToSelf(requestId, requestCreatedById) {
    const selfAssignApiBody = {
      RequestId: requestId,
      UserId: 0,
      AssignmentType: 'Self-Assign'
    };
    this.loading = true;
    this.loadingMyWorklistMessage = 'Assigning Request';
    const apiurl = environment.APIFAsT + '/assignnassessmentrequest/self';
    this.worklistservice.selfAssignApi(apiurl, selfAssignApiBody).subscribe((response) => {
      this.loading = false;
      if (response.result === 'Success') {
        this.sendResponseEmail('Assign', requestId, requestCreatedById, '', '', this.selectedRowData.cucDescription);
        this.selfAssignPopUp = true;
      }
    }, (error) => {
      this.loading = false;
    });
  }

  // CG - Send response email to respective RA/R&D users.
  /* istanbul ignore next */
  sendResponseEmail(notificationGroup, displayRequestId, toemailids, bodyData, ccemaillist, cucDescription) {
    const data = {
      NotificationGroup: notificationGroup,
      Subject: null,
      EmailBody: bodyData,
      ToEmailList: toemailids,
      FromEmail: null,
      CCEmailList: ccemaillist,
      EmailType: 'html',
      UPD: this.selectedRowData.divisionid,
      PureCodes: null,
      RequestID: displayRequestId,
      MarketId: this.selectedRowData.marketId,
      CUCDescription: cucDescription
    };
    const apiurl = environment.APIFAsT + '/responseemail';
    this.worklistservice.postData(apiurl, data).subscribe((response) => {
      this.loading = false;
      if (response.result === 'Success') {
        if (notificationGroup === 'Assign') {
        }
      }
    }, (error) => {
      this.loading = false;
    });
  }
  MyWlForwardToRARequest(event) {
    if (this.selectedRowData.requestStatus !== 'Deleted' && this.selectedRowData.requestStatus !== 'Rejected'
      && this.selectedRowData.requestStatus !== 'Completed') {
      this.delegateORForward = 'forward';
      this.getMyWlUsersForCountryAndDivision();
    } else {
      this.matSnackBar.open(this.selectedRowData.requestStatus + ' Request cannot be Forward To RA', 'close', {
        duration: 3900,
        panelClass: ['error']
      });
    }
    event.stopPropagation();
    return false;
  }
  RnDUserDelete(event) {
    this.rejectionComments = '';
    if (this.selectedRowData.requestStatus === 'Submitted' || this.selectedRowData.requestStatus === 'Re-Submitted') {
      this.deleteDialog = true;
      this.rndDelete = true;
      this.rejectRequest = false;
    } else if (this.selectedRowData.requestStatus !== 'Deleted' && this.selectedRowData.requestStatus !== 'Rejected'
      && this.selectedRowData.requestStatus !== 'Completed') {
      this.deleteDialog = true;
      this.rndDelete = true;
      this.rejectRequest = false;
    } else {
      this.matSnackBar.open(this.selectedRowData.requestStatus + ' Request cannot Delete..', 'close', {
        duration: 3900,
        panelClass: ['error']
      });
    }
    event.stopPropagation();
    return false;
  }
  MyWlDeleteRequest(event) {
    this.rejectionComments = '';
    if (this.selectedRowData.requestStatus === 'Assigned') {
      this.deleteDialog = true;
      this.rndDelete = false;
      this.rejectRequest = true;
    } else {
      this.matSnackBar.open(this.selectedRowData.requestStatus + ' Request cannot Reject..', 'close', {
        duration: 3900,
        panelClass: ['error']
      });
    }
    event.stopPropagation();
    return false;
  }
  MyWlCompleteRequest(event) {
    localStorage.setItem('isViewReportClicked', 'true');
    localStorage.setItem('requestStatus', 'Completed');
    this.dataTrans.setComponentName('SubmitReportRA');
    this.loadingMyWorklistMessage = 'Getting FAsT Report';
    const apiurl = environment.APIFAsT + '/reportrequest/' + this.selectedRowData.requestDisplayID;
    this.loading = true;
    this.worklistservice.getData(apiurl).subscribe((response) => {
      if (response !== null) {
        const val: any = {
          requestID: response.requestDisplayID,
          requestStatus: response.requestStatus
        };
        this.worklistservice.setRecipeData(val);
        recipeModel.requestDisplayID = response.requestDisplayID;
        this.sendRequestId(response);
        localStorage.setItem('CompletedReport', JSON.stringify(response));
        this.dataTrans.setSelectedStep(3);
        setTimeout(() => {
          this.router.navigate(['assess-request-ra/submit-report']);
        }, 500);
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }
  MyWlDelegateRequest(event) {
    if (this.selectedRowData.requestStatus !== 'Deleted' && this.selectedRowData.requestStatus !== 'Rejected'
      && this.selectedRowData.requestStatus !== 'Completed') {
      this.delegateORForward = 'delegate';
      this.getMyWlUsersForCountryAndDivision();
    } else {
      this.matSnackBar.open(this.selectedRowData.requestStatus + ' Request cannot be Delegated', 'close', {
        duration: 3900,
        panelClass: ['error']
      });
    }
    event.stopPropagation();
    return false;
  }
  onMyWlDelegate() {
    this.listOfEmails.push(this.deligatOrForwardEmailId);
    const delegateApiBody = {
      AssignmentType: 'Delegate',
      RequestId: this.selectedRowData.requestDisplayID,
      DelegatOrForwardEmailIds: this.listOfEmails,
      DelegatOrForwardComment: this.deligateComment
    };
    const statusUpdateApiUrl = environment.APIFAsT + '/assignnassessmentrequest/delegate';
    this.loading = true;
    this.loadingMyWorklistMessage = 'Request Delegating';
    this.worklistservice.postData(statusUpdateApiUrl, delegateApiBody).subscribe((statusresponse) => {
      this.loading = false;
      if (statusresponse.result === 'Success') {
        this.sendResponseEmail(delegateApiBody.AssignmentType, delegateApiBody.RequestId, delegateApiBody.DelegatOrForwardEmailIds[0], delegateApiBody.DelegatOrForwardComment, this.selectedRowData.submittedByUserId, this.selectedRowData.cucDescription);
        this.matSnackBar.open('Request ' + this.selectedRowData.requestDisplayID + ' Delegated succesfully', 'close', {
          duration: 3200,
          panelClass: ['success']
        });
      } else {
        this.matSnackBar.open(statusresponse.result, 'close', {
          duration: 3200,
          panelClass: ['error']
        });
      }
      this.getMyWorkList();
      this.listOfEmails = [];
      this.deligatOrForwardEmailId = null;
    }, (error) => {
      this.loading = false;
    });

  }
  onMyWlForwardToRa() {
    this.listOfEmails.push(this.deligatOrForwardEmailId);
    const delegateApiBody = {
      AssignmentType: 'ForwardToRA',
      RequestId: this.selectedRowData.requestDisplayID,
      DelegatOrForwardEmailIds: this.listOfEmails,
      DelegatOrForwardComment: this.forwardToRaComment
    };
    const statusUpdateApiUrl = environment.APIFAsT + '/assignnassessmentrequest/forward';
    this.loading = true;
    this.loadingMyWorklistMessage = 'Request forwarding';
    this.worklistservice.postData(statusUpdateApiUrl, delegateApiBody).subscribe((statusresponse) => {
      this.loading = false;
      if (statusresponse.result === 'Success') {
        this.sendResponseEmail('forward', delegateApiBody.RequestId, delegateApiBody.DelegatOrForwardEmailIds[0], delegateApiBody.DelegatOrForwardComment, this.selectedRowData.submittedByUserId, this.selectedRowData.cucDescription);
        this.matSnackBar.open('Request ' + this.selectedRowData.requestDisplayID + ' forwarded succesfully', 'close', {
          duration: 3200,
          panelClass: ['success']
        });
      } else {
        this.matSnackBar.open(statusresponse.result, 'close', {
          duration: 3200,
          panelClass: ['error']
        });
      }
      this.getMyWorkList();
      this.listOfEmails = [];
      this.deligatOrForwardEmailId = null;
    }, (error) => {
      this.loading = false;
    });


  }
  onMyWlDeleteRequest(type) {
    if (type === 'reject') {
      this.requestStatus = 'Rejected';
      this.emailRequestStatus = 'Reject';
    } else if (type === 'delete') {
      this.requestStatus = 'Deleted';
      this.emailRequestStatus = 'Deleted';
    }
    const statusUpdateApiUrl = environment.APIFAsT + '/assessment/request/' + this.selectedRowData.requestDisplayID + '/status/' + this.requestStatus + '/comment/' + this.rejectionComments;
    this.loading = true;
    this.loadingMyWorklistMessage = 'Request Status Update';
    this.worklistservice.putApiData(statusUpdateApiUrl).subscribe((statusresponse) => {
      if (statusresponse.message !== null) {
        this.loading = false;
        this.sendResponseEmail(this.emailRequestStatus, this.selectedRowData.requestDisplayID, this.selectedRowData.submittedByUserId, this.rejectionComments, '', this.selectedRowData.cucDescription);
        this.matSnackBar.open(statusresponse.message, 'close', {
          duration: 3200,
          panelClass: ['success']
        });
        this.getMyWorkList();
      }
    }, (error) => {
      this.loading = false;
    });

  }

  getMyWlUsersForCountryAndDivision() {
    this.loadingMyWorklistMessage = 'Fetching Users';
    this.deligatOrForwardEmailId = null;
    this.forwardToRaComment = '';
    this.deligateComment = '';
    this.loading = true;
    const fetchUserApiUrl = environment.APIFAsT + '/users/market/' + this.selectedRowData.marketId + '/upd/' + this.selectedRowData.topTfcId;
    this.worklistservice.getData(fetchUserApiUrl).subscribe((response) => {
      this.loading = false;
      if (response.length !== 0) {
        this.usersList = response;
      }
      if (this.delegateORForward === 'delegate') {

        this.delegateDialog = true;
      } else if (this.delegateORForward === 'forward') {
        this.forwardToRaPopUp = true;
      }

    }, (error) => {
      this.loading = false;
    });

  }
  searchMyWlUsersList(event) {

    const filtered: any[] = [];
    const query = event.query;
    for (const value of this.usersList) {
      this.userVal = value.userEmailId;
      if (this.userVal.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(this.userVal);
      }
    }
    this.filteredUserListVals = filtered;
    this.validateEmail(this.filteredUserListVals);

  }
  /* istanbul ignore next */
  validateEmail(email) {
    if (email.length === 1 && email[0].endsWith('@unilever.com')) {
      this.deligatOrForwardEmailId = email[0];
      this.delegateOrForwardBtnFlag = false;
    } else {
      this.delegateOrForwardBtnFlag = true;
    }

  }
  onEmailIdClear() {
    this.deligatOrForwardEmailId = null;
    this.filteredUserListVals = [];
    this.delegateOrForwardBtnFlag = true;
  }
  onDropdownSelect(event) {
    this.deligatOrForwardEmailId = event;
    this.filteredUserListVals.push(this.deligatOrForwardEmailId);
    this.delegateOrForwardBtnFlag = false;
  }
  onDelegateCancelClick() {
    this.deligatOrForwardEmailId = null;
    this.delegateDialog = false;
    this.deligateComment = null;
  }
  onForwardCancelClick() {
    this.forwardToRaPopUp = false;
    this.deligatOrForwardEmailId = null;
    this.forwardToRaComment = null;
  }
  /* istanbul ignore next */
  onClearFilters(masterWork) {
    if (this.myworklistColumnsCustomisedToShow.length) {
      this.myWorklistTableColumnsClear = this.myworklistColumnsCustomisedToShow;
    } else {
      this.myWorklistTableColumnsClear = this.allMyWorklistColumns;
    }
    masterWork.reset();
    this.filteredRows = [];
    for (const val of this.myWorklistTableColumnsClear) {
      if (val.fieldName !== 'check') {
        const colname = val.fieldName + '_val';
        if ((<HTMLInputElement> document.getElementById(colname)) && (<HTMLInputElement> document.getElementById(colname)).value) {
          (<HTMLInputElement> document.getElementById(colname)).value = '';
        }
      }
    }
  }
  onCreateReqBtn() {
    requestFlags.viewRequest = false;
    requestFlags.EditRequest = false;
    requestFlags.createRequest = true;
    this.dataTrans.setComponentName('AssessRequestsRD');
    setTimeout(() => {
      this.dataTrans.setSelectedStep(0);
    }, 200);
    this.route.navigate(['/assess-requests/select-recipe']);
  }
  editRequestCheck() {
    this.rejectionComments = '';
    if (this.selectedRowData.requestStatus === 'Submitted' || this.selectedRowData.requestStatus === 'Re-Submitted' || this.selectedRowData.requestStatus === 'SentBack') {
      this.viewOrEditRequest('edit');
    } else {
      this.matSnackBar.open(this.selectedRowData.requestStatus + ' Request cannot be Edit', 'close', {
        duration: 3900,
        panelClass: ['error']
      });
    }
  }

  viewOrEditRequest(type) {
    requestFlags.createRequest = false;
    if (type === 'view') {
      requestFlags.viewRequest = true;
      requestFlags.EditRequest = false;

    } else if (type === 'edit') {
      requestFlags.viewRequest = false;
      requestFlags.EditRequest = true;
    }
    this.loadingMyWorklistMessage = 'Fetching Request Details';
    const apiurl = environment.APIFAsT + '/executionrequest/' + this.selectedRowData.requestDisplayID;
    this.loading = true;
    this.worklistservice.getData(apiurl).subscribe((response) => {
      if (response !== null) {
        this.worklistSelectedData = response;
        this.dataTrans.setCompositionData(this.worklistSelectedData.formulationRequestDetails);
        this.dataTrans.setPropertyData(this.worklistSelectedData.productPropertiesInputs);
        this.dataTrans.setMultiComponentData(this.worklistSelectedData.multiComponent);
        this.formAssess.requestDisplayID = this.selectedRowData.requestDisplayID;
        this.formAssess.division = this.selectedRowData.division;
        this.formAssess.formulationCategory = this.selectedRowData.rDFormulationCategory;
        this.formAssess.formulationType = this.selectedRowData.formulationType;
        this.formAssess.formulationClass = this.selectedRowData.formulationClass;
        this.formAssess.formulationSubClass = this.selectedRowData.formulationSubClass;
        this.formAssess.fc = this.worklistSelectedData.cucCode;
        this.formAssess.plmkeydate = this.worklistSelectedData.keyDate;
        this.formAssess.plmvalidityarea.validityDescription = this.worklistSelectedData.plmValidityArea;
        this.formAssess.alternate = this.worklistSelectedData.cucAlt;
        this.formAssess.version = this.worklistSelectedData.cucVer;
        this.formAssess.recipeType = this.worklistSelectedData.recipeType;
        this.formAssess.description = this.worklistSelectedData.cucDescription;
        this.formAssess.name = null;
        this.formAssess.uom = null;
        this.formAssess.intendedUse = this.worklistSelectedData.intendedUse;
        this.formAssess.prepMethod = this.worklistSelectedData.preparationMethod;
        this.formAssess.comments = this.worklistSelectedData.requestComments;
        this.formAssess.dosage = this.worklistSelectedData.dosage;
        this.formAssess.servingSize = this.worklistSelectedData.servingSize;
        this.formAssess.dosageUom = this.worklistSelectedData.dosageUnit;
        this.formAssess.selectedMarkets = [this.worklistSelectedData.marketDetails];
        this.formAssess.market = this.worklistSelectedData.marketDetails;
        this.formAssess.tfcList = this.worklistSelectedData.tfc;
        this.formAssess.firstRequest = this.worklistSelectedData.firstRequest;
        this.formAssess.cucChanged = this.worklistSelectedData.cucCode;
        this.formAssess.keyDate = this.worklistSelectedData.keyDate;
        this.formAssess.requestStatus = this.selectedRowData.requestStatus;
        this.formAssess.isMultiComponent = this.worklistSelectedData.isMultiComponent;
        this.formAssess.multiComponent = this.worklistSelectedData.multiComponent;
        this.formAssess.dosageForMarkets = this.worklistSelectedData.dosageForMarkets;
        this.formAssess.raUserId = this.worklistSelectedData.raUserID;
        this.formAssess.createdByUserId = this.selectedRowData.submittedByUserId;
        this.formAssess.ifraComplianceModelList = this.worklistSelectedData.ifraComplianceModelList;
        this.formAssess.regionalComplianceLevel2ModelList = this.worklistSelectedData.regionalComplianceLevel2ModelList;
        this.formAssess.regionalComplianceLevel3ModelList = this.worklistSelectedData.regionalComplianceLevel3ModelList;
        this.formAssess.projectdetailData.push(this.worklistSelectedData.marketDetails);
        this.worklistservice.setRequestStatus(this.selectedRowData.requestStatus);
      }
      localStorage.setItem('FormObj', JSON.stringify(this.formAssess));
      const val: any = {
        requestID: this.formAssess.requestDisplayID,
        requestStatus: this.formAssess.requestStatus
      };
      this.worklistservice.setRecipeData(val);
      this.dataTrans.setSelectedStep(2);
      this.router.navigate(['assess-requests/submit-request']);

      this.loading = false;

    }, (error) => {
      this.loading = false;
    });
  }
  /* istanbul ignore next */
  selectRow(checkValue) {
    if (checkValue) {
      this.selectedRows = this.myworkListData;
    } else {
      this.selectedRows = [];
    }
  }
  /* istanbul ignore next */
  showColumnsDialog() {
    this.initiateColumnOptions();
    this.columnsDialog = true;
    this.position = 'right';
  }

  cancel() {
    this.columnsDialog = false;
  }
  /* istanbul ignore next */
  saveColumnChange(action) {
    this.columnsDialog = false;
    this.loading = true;
    const columnsAddedData = [];
    let activePayload;
    if (action === 'save') {
      activePayload = 1;
    } else if (action === 'reset') {
      activePayload = 0;
    }
    this.columnsAdded.forEach((column, i) => {
      const data = {
        pageName: 'newmyworklist',
        fieldName: column.fieldName,
        fieldDescription: column.fieldDescription,
        sortOrder: i + 1,
        status: column.status,
        active: activePayload,
        width: column.width
      };
      columnsAddedData.push(data);
    });
    const apiurl = environment.APIFAsT + '/SaveColumnCustomization';
    this.worklistservice.postData(apiurl, columnsAddedData).subscribe((response) => {
      this.getColumnCustomization();
    });
  }
  /* istanbul ignore next */
  drop(event: CdkDragDrop<string[]>) {
    if (event.currentIndex !== 1 && event.currentIndex !== 0) {
      moveItemInArray(this.columnsAdded, event.previousIndex, event.currentIndex);
    }
  }
  /* istanbul ignore next */
  addColumn(): void {
    (this.userForm.get('column') as FormArray).push(
      this.fb.control(null)
    );
    this.columnToBeAddedDetails.forEach(colDetail => {
      this.columnsAdded.push(colDetail);
      this.columnListToAdd = this.columnListToAdd.filter(x => x.fieldName !== colDetail.fieldName);
    });
    this.columnToBeAddedDetails = [];
    this.selectedColumns = [];
  }
  /* istanbul ignore next */
  onColumnChange(value) {
    if (value.fieldName !== 'check') {
      const column = this.orgMyWorklistColumns.filter(x => x.fieldName === value.fieldName);
      const columnData = column[0];
      columnData.sortOrder = this.columnsAdded.length;
      columnData.status = 1;
      this.columnToBeAddedDetails.push(columnData);
    }
  }

  onDeSelectAllColumn() {
    this.columnToBeAddedDetails = [];
  }

  onSelectAllColumn(value) {
    value.forEach(col => {
      if (value.fieldName !== 'check') {
        const column = this.orgMyWorklistColumns.filter(x => x.fieldName === col.fieldName);
        const columnData = column[0];
        columnData.sortOrder = this.columnsAdded.length;
        columnData.status = 1;
        this.columnToBeAddedDetails.push(columnData);
      }
    });
  }

  removeColumn(col) {
    this.columnsAdded = this.columnsAdded.filter(x => x.fieldName !== col.fieldName);
    const removedCol = this.allMyWorklistColumns.filter(x => x.fieldName === col.fieldName);
    this.columnListToAdd.push(removedCol[0]);
  }
  /* istanbul ignore next */
  getColumnDetails(): AbstractControl[] {
    return (<FormArray> this.userForm.get('column')).controls;
  }

  /* istanbul ignore next */
  getMyWLUserRoleDetails() {
    this.myWLuserDetails = JSON.parse(localStorage.getItem('userRolesPermissions'));
    if (this.myWLuserDetails !== undefined && this.myWLuserDetails.roles !== undefined && this.myWLuserDetails.roles !== null && this.myWLuserDetails.roles.length > 0) {
      this.myWLuserDetails.roles.forEach(c => {
        if (c.roleId === 150) {
          this.isSuperAdmin = true;
        }
        if (c.roleId === 140 || c.roleId === 130 || c.roleId === 160 || c.roleId === 170) {
          this.isRA = true;
        }
        if (c.roleId === 120 || c.roleId === 110 || c.roleId === 170) {
          this.isRnD = true;
        }
        if (c.roleId === 110 || c.roleId === 170) {
          this.isRnDReq = true;
        }
      });
    }
  }
  /* istanbul ignore next */
  splitLinks(comment, filed) {
    const regex = /<a[^>]*href=["']([^"']*)["']/gm;
    const aregText = /<a(.*?)<\/a>/gm;
    this.CommentsHyperLinks = [];
    let m;
    let getAncharText;
    const totalAnchor = [1, 2, 3, 4, 5, 6, 7];
    const textbetweenTags = [];
    for (const val of totalAnchor) {
      getAncharText = aregText.exec(comment);
      if (getAncharText == null) {
        break;
      }
      if (getAncharText.index === aregText.lastIndex) {
        aregText.lastIndex++;
      }
      getAncharText.forEach((amatch, agroupIndex) => {
        if (agroupIndex === 1) {
          const aindex = amatch.indexOf('>');
          const matchLength = amatch.length;
          textbetweenTags.push(amatch.substring(aindex + 1, matchLength));
        }
      });
    }
    let index = 0;

    for (const val of totalAnchor) {
      m = regex.exec(comment);
      if (m == null) {
        break;
      }
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      // The result can be accessed through the `m`-variable.
      m.forEach((match, groupIndex) => {
        if (groupIndex === 1) {
          this.CommentsHyperLinks.push(
            {
              text: this.handleSpecialCharactersForHyperlinks(textbetweenTags[index]),
              hyperlink: match,
              tooltip: match
            });
          index = index + 1;
        }
      });
    }
  }
  splitLinksReport(comment, filed) {
    const regex = /<a[^>]*href=["']([^"']*)["']/gm;
    const aregText = /<a(.*?)<\/a>/gm;
    this.ReportCommentsHyperLinks = [];
    let m;
    let getAncharText;
    const totalAnchor = [1, 2, 3, 4, 5, 6, 7];
    const textbetweenTags = [];
    for (const val of totalAnchor) {
      getAncharText = aregText.exec(comment);
      if (getAncharText == null) {
        break;
      }
      if (getAncharText.index === aregText.lastIndex) {
        aregText.lastIndex++;
      }

      getAncharText.forEach((amatch, agroupIndex) => {
        if (agroupIndex === 1) {
          const aindex = amatch.indexOf('>');
          const matchLength = amatch.length;
          textbetweenTags.push(amatch.substring(aindex + 1, matchLength));
        }
      });
    }
    let index = 0;

    for (const val of totalAnchor) {
      m = regex.exec(comment);
      if (m == null) {
        break;
      }
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      // The result can be accessed through the `m`-variable.
      m.forEach((match, groupIndex) => {
        if (groupIndex === 1) {
          this.ReportCommentsHyperLinks.push(
            {
              text: this.handleSpecialCharactersForHyperlinks(textbetweenTags[index]),
              hyperlink: match,
              tooltip: match
            });
          index = index + 1;
        }
      });
    }
  }

  /* istanbul ignore next */
  addExtraColumns() {
    this.isColumnsHasHyperLinks = this.myWorklistTableExcelColumns.filter(x => x.fieldName === 'rdComment').length > 0 ? 'Yes' : 'No';
    if (this.isColumnsHasHyperLinks === 'Yes') {
      WORKLIST_EXCEL_ADDITIONAL_COLUMNS.forEach(col => {
        this.myWorklistTableExcelColumns.push(
          {
            fieldName: col.fieldName,
            fieldDescription: col.fieldDescription
          }
        );
      });
    }
  }
  /* istanbul ignore next */
  addExtraColumnsReport() {
    this.isColumnsHasHyperLinks = this.myWorklistTableExcelColumns.filter(x => x.fieldName === 'reportComments').length > 0 ? 'Yes' : 'No';
    if (this.isColumnsHasHyperLinks === 'Yes') {
      WORKLIST_EXCEL_ADDITIONAL_COLUMNS_REPORT.forEach(col => {
        this.myWorklistTableExcelColumns.push(
          {
            fieldName: col.fieldName,
            fieldDescription: col.fieldDescription
          }
        );
      });
    }
  }
  closePopup() {
    this.isClickHere = false;
  }
  cloasePopupComment() {
    this.isClickHereComments = false;
  }
  /* istanbul ignore next */
  openComment(data) {
    this.isClickHere = true;
    const mydata = data.comments;
    this.tableComment = mydata.replace('<a', '<a target="_blank"');
  }
  /* istanbul ignore next */
  changeComment(input) {
    const strValue = input.comments;
    this.commentRD = '';
    const ar = strValue.match(/<a.*?<\/a>/g);
    if ((ar !== null) && (ar.length === 0 || ar.length > 0)) {
      this.commentRD = 'Click Here';
    } else {
      this.commentRD = input.comments;
    }
    return this.commentRD;
  }
  /* istanbul ignore next */
  openReportComment(data) {
    this.isClickHereComments = true;
    const mydata = data.rpcomments;
    this.tableComment = mydata.replace('<a', '<a target="_blank"');
  }
  /* istanbul ignore next */
  changeReportComment(input) {
    const strValue = input;
    let commentRD;
    const ar = strValue.match(/<a.*?<\/a>/g);
    if ((ar !== null) && (ar.length === 0 || ar.length > 0)) {
      commentRD = 'Click Here';
    } else {
      commentRD = input;
    }
    return commentRD;
  }
  handleSpecialCharacters(text) {
    if (text !== null && text !== '') {
      return text.replace(/<.*?>/g, ' ').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;.*?/gi, ' ').replace(/&amp;/g, '&');
    } else {
      return '';
    }
  }
  handleSpecialCharactersForHyperlinks(text) {
    if (text !== null && text !== '') {
      return text.replace(/&nbsp;.*?/gi, ' ').replace(/&amp;/g, '&').replace(/<strong>/gi, '').replace(/<\/strong>/gi, '').replace(/<i>/gi, '').replace(/<\/i>/gi, '');
    } else {
      return '';
    }
  }
  /* istanbul ignore next */
  getActionStatusBasedonRole(marketId, divisionId, actionType, reqCategoryID) {
    let selfAssignRequest = true;
    let delegateRequest = true;
    let forwardtoRaRequest = true;
    let rejectRequest = true;
    let actionStatus = true;
    let deleteRequest = true;
    let editRequest = true;
    if (this.myWLuserDetails !== null && this.myWLuserDetails !== undefined && this.myWLuserDetails.roles !== undefined && this.myWLuserDetails.roles !== null) {
      const fastRoleCount = this.myWLuserDetails.roles.filter(f => f.roleId >= 110).length;
      const isSuperAdminUser = this.myWLuserDetails.roles.filter(f => f.roleId === 150).length > 0;
      const isRARDUser = this.myWLuserDetails.roles.filter(f => f.roleId === 170);
      const isRAUser = this.myWLuserDetails.roles.filter(f => f.roleId === 130 || f.roleId === 140);
      const isRDUser = this.myWLuserDetails.roles.filter(f => f.roleId === 110);
      if (fastRoleCount > 1 && !isSuperAdminUser && isRARDUser.length > 0) {
        if (isRARDUser[0].market !== null || isRARDUser[0].tfc !== null) {
          const isRARDRowMatched = (isRARDUser.length > 0 && isRARDUser.filter(m => ((m.market !== null && m.market.marketId === marketId) || m.market === null) && ((m.tfc !== null && m.tfc.tfcId === divisionId) || m.tfc === null)).length > 0);
          const isRARowMatched = (isRAUser.length > 0 && isRAUser.filter(m => ((m.market !== null && m.market.marketId === marketId) || m.market === null) && ((m.tfc !== null && (m.tfc.tfcId === divisionId || m.tfc.tfcId === reqCategoryID)) || m.tfc === null)).length > 0);
          const isRDRowMatched = (isRDUser.length > 0 && isRDUser.filter(m => ((m.market !== null && m.market.marketId === marketId) || m.market === null) && ((m.tfc !== null && (m.tfc.tfcId === divisionId || m.tfc.tfcId === reqCategoryID)) || m.tfc === null)).length > 0);
          if (isRARDRowMatched || isRARowMatched || isRDRowMatched) {
            switch (actionType) {
              case 'deleteRequest': {
                deleteRequest = isRARowMatched && !isRARDRowMatched ? false : true;
                break;
              }
              case 'editRequest': {
                editRequest = isRARowMatched && !isRARDRowMatched ? false : true;
                break;
              }
              default: {
                selfAssignRequest = isRDRowMatched && !isRARDRowMatched ? false : true;
                delegateRequest = isRDRowMatched && !isRARDRowMatched ? false : true;
                forwardtoRaRequest = isRDRowMatched && !isRARDRowMatched ? false : true;
                rejectRequest = isRDRowMatched && !isRARDRowMatched ? false : true;
              }
            }
          } else {
            selfAssignRequest = false;
            delegateRequest = false;
            forwardtoRaRequest = false;
            rejectRequest = false;
            deleteRequest = false;
            editRequest = false;
          }
        }
      }
    }
    switch (actionType) {
      case 'selfAssignRequest':
        actionStatus = selfAssignRequest;
        break;
      case 'delegateRequest':
        actionStatus = delegateRequest;
        break;
      case 'forwardtoRaRequest':
        actionStatus = forwardtoRaRequest;
        break;
      case 'rejectRequest':
        actionStatus = rejectRequest;
        break;
      case 'deleteRequest':
        actionStatus = deleteRequest;
        break;
      case 'editRequest':
        actionStatus = editRequest;
        break;
    }
    return actionStatus;
  }
}
